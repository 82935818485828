import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Grid, MenuItem, TextField, Typography, Autocomplete } from "@mui/material"
import { Cancel, Save, Update, DriveFileRenameOutline } from '@mui/icons-material'
import { LookupTablesContext } from "../../../context/LookupTablesContext"
import { handleNumberInput } from '../../Utils'
import axios from "axios"
import { useMsal } from "@azure/msal-react"
// import SuggestedBundlesTable from "./SuggestedBundlesTable"
import { AutocompleteOption } from "../../../utilities/generalPurposeTypes"
import { toast } from "react-toastify"
import { toastError, toastSuccess } from "../../assets/customToasts"
import { DefaultBillingsTableMedium } from "./DefaultBillingsTable"
import { ViewDefaultBilling } from "./DefaultBillings"
import { DateTime } from "luxon"
import { ViewBundle } from "./types"

type BundlesFormProps = {
  selectedBundle: ViewBundle | null
  onClose: () => void
  refreshTable: () => Promise<void>
}

type Form = {
  id: number | ''
  name: string
  fk_id_voucher_type: number | ''
  voucher_duration_days: number | ''
  voucher_amount: number | ''
  reference_year: number | ''
  description: string | null
}

export type Filters = {
  affiliation: AutocompleteOption | null
  program: string | number
  service: string | number
  year: number
}

export type SuggestedBundles = {
  fk_id_program: number
  program_name: string
  fk_id_voucher_type: number
  voucher_type_name: string
  voucher_type_tag: string
  quantity: number
}

export type ExpectedVouchersPerStudent = {
  fk_id_student: number
  student_full_name: string
  fk_id_program: number
  program_name: string
  fk_id_grade: number
  grade_name: string
  number_of_courses: number
  required_vouchers: SuggestedBundles[]
}

export default function BundlesForm({ selectedBundle, onClose, refreshTable }: BundlesFormProps) {
  // Hooks
  const { accounts } = useMsal()
  const { lookupTables, refreshLookupTables } = useContext(LookupTablesContext)
  // General
  const [disableSave, setDisableSave] = useState<boolean>(false)
  // Data
  const [form, setForm] = useState<Form>(selectedBundle ?
    {
      id: selectedBundle.id,
      name: selectedBundle.name,
      fk_id_voucher_type: selectedBundle.fk_id_voucher_type,
      voucher_duration_days: selectedBundle.voucher_duration_days,
      voucher_amount: selectedBundle.voucher_amount,
      reference_year: selectedBundle.reference_year || '',
      description: selectedBundle.description
    }
    :
    {
      id: '',
      name: '',
      fk_id_voucher_type: '',
      voucher_duration_days: 365,
      voucher_amount: '',
      reference_year: DateTime.utc().get('year'),
      description: ''
    })
  const [defaultBillings, setDefaultBillings] = useState<ViewDefaultBilling[]>([])
  const [selectedRowsIds, setSelectedRowsIds] = useState<number[]>(selectedBundle ? selectedBundle.default_billings.map((_) => _.fk_id_default_billing) : [])
  const [suggestedBundles, setSuggestedBundles] = useState<SuggestedBundles[]>([])
  const [loadingSuggestedBundles, setLoadingSuggestedBundles] = useState(false)
  // useMemos
  const editMode = useMemo(() => Boolean(form.id), [form.id])

  // function copyValuesFromSuggestedBundle(voucherTypeID: number, quantity: number, programID: number) {
  //   setForm(prev => ({ ...prev, fk_id_voucher_type: voucherTypeID, voucher_amount: quantity }))
  //   setFilters(prev => ({ ...prev, program: programID }))
  // }

  const [filters, setFilters] = useState<Filters>({
    affiliation: null,
    program: 'all',
    service: 'all',
    year: DateTime.utc().get('year')
  })
  // Memos
  const affiliationOptions = useMemo<AutocompleteOption[]>(() => {
    return lookupTables.affiliation.map(aff => ({ label: `${aff.tag} - ${aff.option}`, id: aff.id }))
  }, [lookupTables])
  const isFormSubmittable = useMemo(() => {
    if (!Boolean(form.name) || !Boolean(form.fk_id_voucher_type) || !Boolean(form.voucher_duration_days) || !Boolean(form.voucher_amount) || !Boolean(form.reference_year) || !selectedRowsIds.length) return false

    return true
  }, [form, selectedRowsIds])

  const getSuggestedBundles = useCallback(async () => {
    if (!filters.affiliation) return setSuggestedBundles([])

    setLoadingSuggestedBundles(true)
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/suggested-bundles?fk_id_affiliation=${filters.affiliation.id}&year=2024`,
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data.suggested_bundles)

      setSuggestedBundles(data.suggested_bundles)
    } catch (err) {
      console.log(err)
      toast.error('Could not load suggested bundles.')
    }
    setLoadingSuggestedBundles(false)
  }, [filters.affiliation, setSuggestedBundles, setLoadingSuggestedBundles])

  useEffect(() => {
    getSuggestedBundles()
  }, [getSuggestedBundles])

  const getDefaultBillings = useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/default-billings`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setDefaultBillings(data)
    } catch (err) {
      console.log(err)
      toast.error('Could not load default billings.')
    }
  }, [])

  useEffect(() => {
    getDefaultBillings()
  }, [getDefaultBillings])

  const handleSubmit = async () => {
    setDisableSave(true)

    const _toast = toast(selectedBundle ? 'Updating bundle' : 'Creating bundle', { toastId: 'toast-bundles-form-handle-submit', isLoading: true })

    try {
      const { data } = await axios({
        method: selectedBundle ? 'PUT' : 'POST',
        url: selectedBundle ? `${process.env.REACT_APP_SIS_BACKEND_URL}/bundles/${form.id}` : `${process.env.REACT_APP_SIS_BACKEND_URL}/bundles`,
        headers: {
          Authorization: ` Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        },
        data: {
          ...form,
          description: form.description || null,
          defaultBillingsIds: selectedRowsIds,
          updated_by: accounts[0]?.username || 'Cypress testing'
        }
      })

      console.log(data)

      refreshTable()

      refreshLookupTables(true)

      toastSuccess(_toast, selectedBundle ? 'Bundle updated' : 'New bundle created')

      onClose()
    } catch (err) {
      console.log(err)

      toastError(_toast, selectedBundle ? 'Unable to update bundle' : 'Unable to create bundle')
    }

    setDisableSave(false)
  }

  function filterAvailableOptions(row: any, option: string) {
    return lookupTables.defaultBilling.some((_defBill: any) => _defBill[option] === row.id)
  }

  // Pre-populate 'Voucher Duration Days' when selecting a 'Voucher Type'
  // useEffect(() => {
  //   if (form.fk_id_voucher_type !== '' && form.voucher_duration_days === '') {
  //     const voucherType: any = lookupTables.voucherType.find((_voucherType) => _voucherType.id === Number(form.fk_id_voucher_type))
  //     setForm(prevform => ({ ...prevform, voucher_duration_days: voucherType?.default_duration_days }))
  //   }
  // }, [form.fk_id_voucher_type, form.voucher_duration_days, lookupTables])



  // const handleSelectAffiliation = useCallback((affiliationID: string) => {
  //   // Update affiliation filter
  //   setFilters(prev => ({ ...prev, affiliation: affiliationID, program: 'all' }))
  //   // Add className to highlight expected bundles
  //   suggestedBundlesGridRef.current?.classList.add(styles.highlightSuggestedBundles)
  // }, [setFilters, suggestedBundlesGridRef])

  const handleAutoName = useCallback(() => {
    if (lookupTables.loading) return ''

    setForm(prev => {
      const _form = { ...prev }

      const affiliation = lookupTables.affiliation.find(aff => aff.id === filters.affiliation?.id)
      const program = lookupTables.program.find(pr => pr.id === filters.program)
      const schoolStage = lookupTables.schoolStage.find(ss => ss.id === program?.fk_id_school_stage)

      _form.name = `${affiliation?.tag} - ${schoolStage?.option} - ${form.reference_year}`

      return _form
    })
  }, [lookupTables, setForm, filters.affiliation, filters.program])

  // Filters
  const filterByAffiliation = useCallback((defaultBilling: ViewDefaultBilling) => {
    switch (filters.affiliation) {
      case null:
        return true
      default:
        return defaultBilling.fk_id_affiliation === filters.affiliation.id
    }
  }, [filters.affiliation])

  const filterByProgram = useCallback((defaultBilling: ViewDefaultBilling) => {
    switch (filters.program) {
      case 'all':
        return true
      default:
        return defaultBilling.fk_id_program === filters.program
    }
  }, [filters.program])

  const filterByService = useCallback((defaultBilling: ViewDefaultBilling) => {
    switch (filters.service) {
      case 'all':
        return true
      default:
        return defaultBilling.fk_id_service === filters.service
    }
  }, [filters.service])

  // const filterByYear = useCallback((defaultBilling: ViewDefaultBilling) => {
  //   return defaultBilling.year === filters.year
  // }, [filters.year])

  return <Grid container spacing={1}>
    {/* Bundle */}
    <Grid item xs={3}>
      <Button
        id='button-auto-name'
        startIcon={<DriveFileRenameOutline />}
        onClick={handleAutoName}
        disabled={!form.reference_year || !filters.affiliation || !filters.program}
      >
        Auto Name
      </Button>
    </Grid>
    <Grid item xs={9}>
      <TextField
        id='form-field-name'
        label='Bundle Name'
        value={form.name}
        onChange={(e) => setForm({ ...form, name: e.target.value })}
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        id='form-field-voucher-type'
        label='Voucher Type'
        select
        value={form.fk_id_voucher_type}
        onChange={(e) => setForm({ ...form, fk_id_voucher_type: Number(e.target.value) })}
      >
        {
          lookupTables.voucherType.map((voucherType, counter: number) => {
            return <MenuItem key={counter} value={voucherType.id} >
              {voucherType.option}
            </MenuItem>
          })
        }
      </TextField>
    </Grid>
    <Grid item xs={3}>
      <TextField
        id='form-field-voucher-duration-days'
        label='Voucher Duration Days'
        value={form.voucher_duration_days}
        onKeyDown={(e) => handleNumberInput(e)}
        onChange={(e) => setForm(prev => ({ ...prev, voucher_duration_days: Number(e.target.value) || '' }))}
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        id='form-field-voucher-amount'
        label='Voucher Amount'
        value={form.voucher_amount}
        onKeyDown={(e) => handleNumberInput(e)}
        onChange={(e) => setForm(prev => ({ ...prev, voucher_amount: Number(e.target.value) }))}
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        id='form-field-reference-year'
        label='Reference Year'
        value={form.reference_year}
        onChange={(e) => setForm(prev => ({ ...prev, reference_year: Number(e.target.value) }))}
        select
      >
        <MenuItem value='2023'>2023</MenuItem>
        <MenuItem value='2024'>2024</MenuItem>
        <MenuItem value='2025'>2025</MenuItem>
      </TextField>
    </Grid>
    <Grid item xs={12}>
      <TextField
        id='form-field-description'
        label='Description'
        multiline
        rows={3}
        value={form.description}
        onChange={(e) => setForm(prev => ({ ...prev, description: e.target.value }))}
      />
    </Grid>
    {/* Default Billings */}
    <Grid item xs={12}>
      <Typography variant="h5" >
        Default Billings
      </Typography>
    </Grid>
    <Grid item xs={5}>
      <Autocomplete
        id='filter-affiliation'
        renderInput={props => <TextField {...props} label='Affiliation' />}
        options={affiliationOptions}
        value={filters.affiliation}
        onChange={(e, newValue) => setFilters(prev => ({ ...prev, affiliation: newValue }))}
      />
    </Grid>
    {/* <Grid item xs={2}>
      <TextField
        id='bundles-filter-year'
        label='Year'
        value={filters.year}
        onChange={(e) => setFilters(prev => ({ ...prev, year: Number(e.target.value) }))}
        select
      >
        <MenuItem value='2023'>2023</MenuItem>
        <MenuItem value='2024'>2024</MenuItem>
        <MenuItem value='2025'>2025</MenuItem>
      </TextField>
    </Grid> */}
    <Grid item xs={4}>
      <TextField id="filter-program" label='Program' value={filters.program} onChange={(e) => setFilters({ ...filters, program: e.target.value })} select>
        {
          [
            <MenuItem key={-1} value='all'>All</MenuItem>,
            ...lookupTables.program
              .filter((row) => filterAvailableOptions(row, 'fk_id_program'))
              .map((_program, index) => <MenuItem key={index} value={_program.id}>{_program.option}</MenuItem>)
          ]
        }
      </TextField>
    </Grid>
    <Grid item xs={3}>
      <TextField id="filter-service" label='Service' value={filters.service} onChange={(e) => setFilters({ ...filters, service: e.target.value })} select>
        {
          [
            <MenuItem key={-1} value='all' >All</MenuItem>,
            ...lookupTables.service
              .filter((row) => filterAvailableOptions(row, 'fk_id_service'))
              .map((_service, index) => <MenuItem key={index} value={_service.id}>{_service.option}</MenuItem>)
          ]
        }
      </TextField>
    </Grid>
    <Grid item xs={12} height={`${70 + 5 * selectedRowsIds.length}vh`} >
      <DefaultBillingsTableMedium
        data={defaultBillings}
        filteredData={defaultBillings.filter(filterByAffiliation).filter(filterByProgram).filter(filterByService)}
        selectedRowsIds={selectedRowsIds}
        setSelectedRowsIds={setSelectedRowsIds}
      />
    </Grid>
    {/* Buttons */}
    <Grid item xs={4} />
    <Grid item xs={4} >
      <Button
        color="error"
        id='button-cancel'
        startIcon={<Cancel />}
        onClick={onClose}
      >
        Cancel
      </Button>
    </Grid>
    <Grid item xs={4} >
      <Button
        id='button-save'
        disabled={disableSave || !isFormSubmittable}
        startIcon={selectedBundle ? <Update /> : <Save />}
        onClick={handleSubmit}
      >
        Save
      </Button>
    </Grid>
    <Grid item xs={12}>
      {/* <SuggestedBundlesTable
          loading={loadingSuggestedBundles}
          selectedAffiliation={lookupTables.affiliation.find(aff => aff.id === filters.affiliation?.id)?.option}
          suggestedBundles={suggestedBundles}
          copyValuesFromSuggestedBundle={copyValuesFromSuggestedBundle}
        /> */}
    </Grid>
  </Grid>
}

import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, Grid, Tab, Tabs } from "@mui/material"
import { LookupTablesContext } from "../../../context/LookupTablesContext"
import { DateTime } from "luxon"
import axios from "axios"
import { toast } from "react-toastify"
import { formatNumberToMoney, roundTwoDecimals } from "../../Utils"
import { Add, Cancel, CurrencyExchange, Save } from "@mui/icons-material"
import CashflowForm from "./CashflowForm"
import { ViewDefaultBilling } from "../_billings/DefaultBillings"
import PreLoader from "../../PreLoader"
import { useMsal } from "@azure/msal-react"
import CashFlowTable from "./CashFlowTable"
import { toastError, toastSuccess } from "../../assets/customToasts"
import { ViewBilling, ViewBundle } from "./types"

type Props = {
  billings?: number[]
  bundleId?: number
  customerIds?: number[]
  next?: () => void
  onClose: () => void
  referenceYear?: number | null
  refreshTable?: () => Promise<void>
}

type AutocompleteType = {
  id: number;
  label: string;
} | null

export type Form = {
  id?: number,
  affiliation: AutocompleteType,
  defaultBilling: AutocompleteType,
  issueDate: DateTime | null,
  service: AutocompleteType,
  beneficiaryType: number | null,
  beneficiary: AutocompleteType,
  payerType: number | null,
  payer: AutocompleteType,
  payee: number | null,
  referenceYear: number | null,
  currency: number,
  totalBilling: string,
  _totalBilling: number
}

export type Discount = {
  type: 'percentage' | 'setValue',
  percentage: string,
  _percentage: number,
  setValue: string,
  _setValue: number
}

export type CashFlowOptions = {
  mode: 'numberOfBills' | 'setValue',
  numberOfBills: string,
  _numberOfBills: number,
  setValue: string,
  _setValue: number,
  dateOfFirstCashflow: DateTime | null,
  remainder: 'first-bill' | 'last-bill' | 'equal'
}

export type CashFlowInstallment = {
  id?: number
  fk_id_billing?: number
  installment_number: number,
  due_date: DateTime | null
  paid_date: DateTime | null
  grossValue: string
  gross_value: number
  due_value: number
  dueValue: string
  paid_value: number
  fk_id_payment_method: number | null
  comment: string | null
  isModified?: boolean
}

export default function CashFlow({ billings, bundleId, customerIds, next, onClose, referenceYear, refreshTable }: Props) {
  console.log(billings, bundleId, customerIds)
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  const { accounts } = useMsal()
  // General
  const [loading, setLoading] = useState<boolean>(false)
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false)
  // Data
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [forms, setForms] = useState<Form[]>([{
    affiliation: null,
    defaultBilling: null,
    issueDate: DateTime.utc(),
    service: null,
    beneficiaryType: null,
    beneficiary: null,
    payerType: null,
    payer: null,
    payee: null,
    referenceYear: referenceYear || DateTime.utc().get('year'),
    currency: 5,
    totalBilling: '',
    _totalBilling: 0
  }])
  const [discount, setDiscount] = useState<Discount[]>([{
    type: 'percentage',
    percentage: '', // User input is a string so the user can type anything, in case the user type something invalid, we set the error on the component
    _percentage: 0, // Computed value as number, for the calculations
    setValue: '', // User input is a string so the user can type anything, in case the user type something invalid, we set the error on the component
    _setValue: 0 // Computed value as number, for the calculations
  }])
  const [cashFlowOptions, setCashFlowOptions] = useState<CashFlowOptions[]>([{
    mode: 'numberOfBills',
    numberOfBills: '', // User input is a string so the user can type anything, in case the user type something invalid, we set the error on the component
    _numberOfBills: 0, // Computed value as number, for the calculations
    setValue: '', // User input is a string so the user can type anything, in case the user type something invalid, we set the error on the component
    _setValue: 0, // Computed value as number, for the calculations
    dateOfFirstCashflow: DateTime.utc(),
    remainder: 'first-bill'
  }])
  const [cashFlowInstallments, setCashFlowInstallments] = useState<CashFlowInstallment[][]>([[]])
  // useMemos
  const currencySign = useMemo(() => lookupTables.currency.find((_) => _.id === forms[tabIndex].currency)?.sign || 'R$', [lookupTables, forms, tabIndex])

  const isFormSubmittable = useMemo(() => {
    // If there's something wrong in the forms
    if (forms.find((_form) => !_form.service || (!_form.beneficiary && !customerIds?.length) || !_form.payer || !_form.payee || isNaN(_form._totalBilling))) return false
    // If no cash flow has been created
    if (cashFlowInstallments.find((_cashFlow) => !_cashFlow.length)) return false
    // If any cash flow has missing information
    if (cashFlowInstallments.find((_cashFlow) => _cashFlow.find((_installment) => !_installment.due_date || isNaN(_installment.gross_value) || isNaN(_installment.due_value) || !_installment.fk_id_payment_method))) return false

    return true
  }, [forms, cashFlowInstallments])

  // Http requests
  const onSave = async () => {
    setDisableSaveButton(true)

    const _toast = toast('Saving cash flow', { toastId: 'toast-cash-flow-on-save', isLoading: true })

    try {
      // PUT
      if (billings?.length) {
        const cashFlow = cashFlowInstallments.map((_cashFlow) => {
          return _cashFlow
            .map((_installment) => ({
              id: _installment.id,
              fk_id_billing: _installment.fk_id_billing,
              installment_number: _installment.installment_number,
              due_date: _installment.due_date!.toISO({ includeOffset: false }),
              paid_date: _installment.paid_date?.toISO({ includeOffset: false }) || null,
              gross_value: _installment.gross_value,
              due_value: _installment.due_value,
              paid_value: _installment.paid_value,
              fk_id_payment_method: _installment.fk_id_payment_method,
              comment: _installment.comment || null,
              updated_by: accounts[0]?.username || 'Cypress testing'
            }))
        })

        for (const _ of cashFlow) {
          await axios({
            method: 'PUT',
            url: `${process.env.REACT_APP_SIS_BACKEND_URL}/billing-cashflow/${_[0].fk_id_billing}`,
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
            },
            data: { cashFlow: _ }
          })
        }

        toastSuccess(_toast, 'Cash flow saved.')
      }
      // POST
      else {
        if (customerIds) {
          for (const _id of customerIds) {
            for (const [index, _] of forms.entries()) {
              // Billing
              const { data } = await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_SIS_BACKEND_URL}/billings`,
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
                },
                data: {
                  billing: {
                    fk_id_affiliation: _.affiliation!.id,
                    fk_id_service: _.service!.id,
                    fk_id_currency: _.currency,
                    fk_id_payee: _.payee,
                    fk_id_default_billing: _.defaultBilling?.id || null,
                    fk_id_customer_payer: _.payerType === 2 ? _id : _.payer!.id,
                    fk_id_customer_beneficiary: _.beneficiaryType === 2 ? _id : _.beneficiary!.id,
                    fk_id_bundle: bundleId || null,
                    fk_id_service_provided_type: null,
                    reference_year: _.referenceYear
                  }
                }
              })

              const cashFlow = cashFlowInstallments[index].map((_installment) => ({
                fk_id_billing: data.id,
                installment_number: _installment.installment_number,
                due_date: _installment.due_date!.toISO({ includeOffset: false }),
                paid_date: _installment.paid_date?.toISO({ includeOffset: false }) || null,
                gross_value: _installment.gross_value,
                due_value: _installment.due_value,
                paid_value: _installment.paid_value,
                fk_id_payment_method: _installment.fk_id_payment_method,
                comment: _installment.comment,
                updated_by: accounts[0]?.username || 'Cypress testing'
              }))

              // Cash Flow
              await axios({
                method: 'POST',
                url: `${process.env.REACT_APP_SIS_BACKEND_URL}/billing-cashflow`,
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
                },
                data: { cashFlow }
              })
            }
          }
        }
        else {
          for (const [index, _] of forms.entries()) {
            // Billing
            const { data } = await axios({
              method: 'POST',
              url: `${process.env.REACT_APP_SIS_BACKEND_URL}/billings`,
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
              },
              data: {
                billing: {
                  fk_id_affiliation: _.affiliation!.id,
                  fk_id_service: _.service!.id,
                  fk_id_currency: _.currency,
                  fk_id_payee: _.payee,
                  fk_id_default_billing: _.defaultBilling?.id || null,
                  fk_id_customer_payer: _.payer!.id,
                  fk_id_customer_beneficiary: _.beneficiary!.id,
                  fk_id_bundle: null,
                  fk_id_service_provided_type: null,
                  reference_year: _.referenceYear
                }
              }
            })

            const cashFlow = cashFlowInstallments[index].map((_installment) => ({
              fk_id_billing: data.id,
              installment_number: _installment.installment_number,
              due_date: _installment.due_date!.toISO({ includeOffset: false }),
              paid_date: _installment.paid_date?.toISO({ includeOffset: false }) || null,
              gross_value: _installment.gross_value,
              due_value: _installment.due_value,
              paid_value: _installment.paid_value,
              fk_id_payment_method: _installment.fk_id_payment_method,
              comment: _installment.comment,
              updated_by: accounts[0]?.username || 'Cypress testing'
            }))

            // Cash Flow
            await axios({
              method: 'POST',
              url: `${process.env.REACT_APP_SIS_BACKEND_URL}/billing-cashflow`,
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
              },
              data: { cashFlow }
            })
          }
        }

        toastSuccess(_toast, 'Billings and cash flow saved.')
      }

      onClose()

      if (next) next()
      if (refreshTable) refreshTable()
    } catch (err) {
      console.log(err)
      toastError(_toast, 'Could not save cash flow.')
    }

    setDisableSaveButton(false)
  }

  // When editing a billing
  const fetchBillings = useCallback(async () => {
    setLoading(true)

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/billing-cashflow?${billings?.map((_) => `ids[]=${_}`).join('&')}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      const forms: Form[] = data.map((_: ViewBilling) => ({
        id: _.id,
        affiliation: { id: _.beneficiary_fk_id_affiliation, label: _.beneficiary_affiliation_name },
        defaultBilling: _.fk_id_default_billing ? { id: _.fk_id_default_billing, label: _.default_billing_name } : null,
        issueDate: DateTime.fromISO(_.issue_date || '', { zone: 'America/New_York' }),
        service: { id: _.fk_id_service, label: _.service_name },
        beneficiaryType: _.beneficiary_customer_type,
        beneficiary: { id: _.fk_id_customer_beneficiary, label: _.beneficiary_customer_name },
        payerType: _.payer_customer_type,
        payer: { id: _.fk_id_customer_payer, label: _.payer_customer_name },
        payee: _.fk_id_payee,
        currency: 5,
        referenceYear: _.reference_year,
        totalBilling: _.default_value ? formatNumberToMoney(_.default_value) : '',
        _totalBilling: _.default_value || 0
      }))

      const _discount = data.map((_: ViewBilling) => {
        const _percentage = (1 - (_.cashflow.reduce((prev, curr) => prev += curr.due_value, 0) / _.cashflow.reduce((prev, curr) => prev += curr.gross_value, 0))) * 100
        const _setValue = parseFloat((_.cashflow.reduce((prev, curr) => prev += curr.gross_value, 0) - _.cashflow.reduce((prev, curr) => prev += curr.due_value, 0)).toFixed(2))

        return {
          type: 'percentage',
          percentage: Math.round(_percentage).toString(),
          _percentage: Math.round(_percentage),
          setValue: String(_setValue),
          _setValue: _setValue
        }
      })

      const _cashFlowOptions = data.map((_: ViewBilling) => ({
        mode: 'numberOfBills',
        numberOfBills: '0',
        _numberOfBills: 0,
        setValue: '',
        _setValue: 0,
        dateOfFirstCashflow: DateTime.utc(),
        remainder: 'first-bill'
      }))

      const installments = data.map((_billing: ViewBilling) => _billing.cashflow.map((_) => ({
        id: _.id,
        fk_id_billing: _.fk_id_billing,
        installment_number: _.installment_number,
        due_date: DateTime.fromISO(_.due_date, { zone: 'America/New_York' }),
        paid_date: DateTime.fromISO(_.paid_date || '', { zone: 'America/New_York' }),
        grossValue: formatNumberToMoney(_.gross_value),
        gross_value: _.gross_value,
        due_value: _.due_value,
        dueValue: formatNumberToMoney(_.due_value),
        paid_value: _.paid_value,
        fk_id_payment_method: _.fk_id_payment_method,
        comment: _.comment
      })))

      setForms(forms)
      setDiscount(_discount) // These are not necessary for editing, only for the component to work correctly
      setCashFlowOptions(_cashFlowOptions) // These are not necessary for editing, only for the component to work correctly
      setCashFlowInstallments(installments)
    } catch (err) {
      console.log(err)
      toast.error('Could not load billings.')
    }

    setLoading(false)
  }, [setLoading, setForms, setCashFlowInstallments])

  // When creating a bundle
  const setDefaultBillings = useCallback(async () => {
    setLoading(true)

    try {
      const { data }: { data: ViewBundle } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/bundles/${bundleId!}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      const _forms = data.default_billings.map((_) => {
        return {
          affiliation: { id: _.fk_id_affiliation, label: _.affiliation_name },
          defaultBilling: { id: _.fk_id_default_billing, label: _.default_billing_name },
          issueDate: DateTime.utc(),
          service: { id: _.fk_id_service, label: _.service_name },
          beneficiaryType: 2,
          beneficiary: null,
          payerType: _.fk_id_customer_type,
          payer: null,
          payee: _.fk_id_payee,
          referenceYear: data.reference_year || DateTime.utc().get('year'),
          currency: _.fk_id_currency,
          totalBilling: _.default_value ? formatNumberToMoney(_.default_value) : '',
          _totalBilling: _.default_value || 0
        }
      })

      const _discount: Discount[] = data.default_billings.map((_) => ({
        type: 'percentage',
        percentage: '',
        _percentage: 0,
        setValue: '',
        _setValue: 0
      }))

      const _cashFlowOptions: CashFlowOptions[] = data.default_billings.map((_) => ({
        mode: 'numberOfBills',
        numberOfBills: _.max_installments.toString(),
        _numberOfBills: _.max_installments,
        setValue: '',
        _setValue: 0,
        dateOfFirstCashflow: DateTime.utc(),
        remainder: 'first-bill'
      }))

      const _cashFlowInstallments = data.default_billings.map((_) => ([]))

      setForms(_forms)

      setDiscount(_discount)

      setCashFlowOptions(_cashFlowOptions)

      setCashFlowInstallments(_cashFlowInstallments)

      setLoading(false)
    } catch (err) {
      console.log(err)
      toast.error('Could not load default billings.')
    }
  }, [])

  useEffect(() => {
    if (billings) fetchBillings()
    else if (bundleId) setDefaultBillings()
  }, [fetchBillings, setDefaultBillings])

  // Cash flow
  const setCashFlow = useCallback((netValue: number, selectedDefaultBilling: ViewDefaultBilling | null) => {
    const _cashflowInstallments: CashFlowInstallment[] = []

    const _form = forms[tabIndex]

    // Calculating net value per bill
    const dueValuePerBill = cashFlowOptions[tabIndex].mode === 'numberOfBills' ? roundTwoDecimals(netValue / cashFlowOptions[tabIndex]._numberOfBills) : cashFlowOptions[tabIndex]._setValue!
    // Number of installments
    const numberOfInstallments = cashFlowOptions[tabIndex].mode === 'numberOfBills' ? cashFlowOptions[tabIndex]._numberOfBills : Math.floor(netValue / cashFlowOptions[tabIndex]._setValue)
    // Gross value per bill
    const grossValue = roundTwoDecimals(_form._totalBilling / numberOfInstallments)
    // Remainder
    var remainder = netValue

    for (var counter = 0; counter < numberOfInstallments; counter++) {
      remainder -= dueValuePerBill

      _cashflowInstallments.push({
        installment_number: counter + 1,
        comment: null,
        due_date: cashFlowOptions[tabIndex].dateOfFirstCashflow!.plus({ month: counter }),
        dueValue: formatNumberToMoney(dueValuePerBill),
        due_value: dueValuePerBill,
        fk_id_payment_method: selectedDefaultBilling?.fk_id_payment_method || null,
        grossValue: formatNumberToMoney(grossValue),
        gross_value: grossValue,
        paid_date: null,
        paid_value: 0
      })
    }

    // Remainder
    if (remainder > 0) {
      // Rounding the remainder
      remainder = roundTwoDecimals(remainder)

      // Check remainder option
      if (cashFlowOptions[tabIndex].remainder === 'first-bill') {
        _cashflowInstallments[0].due_value += remainder
        _cashflowInstallments[0].dueValue = formatNumberToMoney(_cashflowInstallments[0].due_value)
      }

      if (cashFlowOptions[tabIndex].remainder === 'last-bill') {
        _cashflowInstallments[cashFlowInstallments.length - 1].due_value += remainder
        _cashflowInstallments[cashFlowInstallments.length - 1].dueValue = formatNumberToMoney(_cashflowInstallments[cashFlowInstallments.length - 1].due_value)
      }

      if (cashFlowOptions[tabIndex].remainder === 'equal') {
        const remainderShare = roundTwoDecimals(remainder / numberOfInstallments) || 0.01

        for (var _counter = 0; _counter < numberOfInstallments; _counter++) {
          // console.log(remainder)

          remainder -= remainderShare

          if (remainder <= 0) break
          // Final installment
          if (_counter === numberOfInstallments - 1) {
            _cashflowInstallments[_counter].due_value += remainder
            _cashflowInstallments[_counter].dueValue = formatNumberToMoney(_cashflowInstallments[_counter].due_value)
          }

          _cashflowInstallments[_counter].due_value += remainderShare
          _cashflowInstallments[_counter].dueValue = formatNumberToMoney(_cashflowInstallments[_counter].due_value)
        }
      }
    }

    setCashFlowInstallments((installments) => {
      const _installments = [...installments]

      _installments[tabIndex] = _cashflowInstallments

      return _installments
    })
  }, [forms, cashFlowOptions, tabIndex])

  const clearCashFlow = useCallback(() => {
    setCashFlowInstallments(installments => {
      const _installments = [...installments]

      _installments[tabIndex] = []

      return _installments
    })
  }, [tabIndex, setCashFlowInstallments])

  // Upper buttons
  const resetDueValues = useCallback(() => {
    setCashFlowInstallments((installments) => {
      const _installments = [...installments]

      _installments[tabIndex] = _installments[tabIndex].map((_) => {
        const _dueValue = discount[tabIndex].type === 'setValue' ? roundTwoDecimals(_.gross_value - (discount[tabIndex]._setValue / _installments[tabIndex].length)) : _.gross_value * (1 - discount[tabIndex]._percentage / 100)

        return {
          ..._,
          due_value: _dueValue,
          dueValue: formatNumberToMoney(_dueValue)
        }
      })

      return _installments
    })
  }, [setCashFlowInstallments, discount, tabIndex])

  const addCashFlow = useCallback(() => {
    setCashFlowInstallments((installments) => {
      const _installments = [...installments]

      const _newInstallment: CashFlowInstallment = {
        installment_number: _installments[tabIndex].length + 1,
        comment: null,
        due_date: null,
        dueValue: '',
        due_value: 0,
        fk_id_payment_method: 2,
        grossValue: '',
        gross_value: 0,
        paid_date: null,
        paid_value: 0
      }

      if (billings) _newInstallment.fk_id_billing = billings[tabIndex]

      _installments[tabIndex].push(_newInstallment)

      return _installments
    })
  }, [setCashFlowInstallments, tabIndex])

  return loading ?
    <PreLoader />
    :
    <>
      {/* Form */}
      <Grid item xs={4}>
        <CashflowForm
          cashFlowInstallments={cashFlowInstallments}
          cashFlowOptions={cashFlowOptions}
          discount={discount}
          forms={forms}
          hasPresetBundle={Boolean(bundleId)}
          hasPresetCustomers={Boolean(customerIds)}
          tabIndex={tabIndex}
          clearCashFlow={clearCashFlow}
          setCashFlow={setCashFlow}
          setCashFlowOptions={setCashFlowOptions}
          setDiscount={setDiscount}
          setForms={setForms}
        />
      </Grid>
      {/* Table */}
      <Grid item xs={8}>
        <Grid container spacing={1.5}>
          {/* Tabs */}
          <Grid item xs={12}>
            <Tabs id='cash-flow-form-tabs' value={tabIndex} onChange={(e, newValue) => setTabIndex(Number(newValue))} variant='scrollable' scrollButtons='auto'>
              {
                forms.map((_, index) => <Tab key={index} id={`cash-flow-form-tab-${index}`} value={index} label={_.defaultBilling?.label || _.service?.label || `Billing ${index + 1}`} sx={{ width: 200 }} />)
              }
            </Tabs>
          </Grid>
          {/* Spacer */}
          <Grid item xs={7.5} />
          {/* Buttons */}
          <Grid item xs={3}>
            <Button
              id='button-reset-due-values'
              startIcon={<CurrencyExchange />}
              color='info'
              onClick={resetDueValues}
            >
              Reset due values
            </Button>
          </Grid>
          <Grid item xs={1.5}>
            <Button
              id='button-add-cash-flow'
              startIcon={<Add />}
              onClick={addCashFlow}
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={12}>
            <CashFlowTable
              cashFlowInstallments={cashFlowInstallments}
              currencySign={currencySign}
              tabIndex={tabIndex}
              setCashFlowInstallments={setCashFlowInstallments}
            />
          </Grid>
          {/* Spacer */}
          <Grid item xs={6} />
          {/* Buttons */}
          <Grid item xs={3}>
            <Button
              id='button-cancel'
              color='error'
              startIcon={<Cancel />}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              id='button-save'
              startIcon={<Save />}
              onClick={onSave}
              disabled={disableSaveButton || !isFormSubmittable}
            >
              Save
            </Button>
          </Grid>
          {/* Spacer */}
          <Grid item xs={8} />
        </Grid>
      </Grid >
    </>
}

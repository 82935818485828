import { Autocomplete, Button, Grid, InputAdornment, MenuItem, Paper, TextField, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { LookupTablesContext } from "../../../context/LookupTablesContext"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { DeleteForever, NoteAdd } from "@mui/icons-material"
import { allowFloat, allowMoneyFormat, allowNumbers, formatNumberToMoney, roundTwoDecimals } from "../../Utils"
import { CashFlowInstallment, CashFlowOptions, Discount, Form } from "./CashFlow"
import axios from "axios"
import { toast } from "react-toastify"
import { ViewDefaultBilling } from "../_billings/DefaultBillings"
import { DateTime } from "luxon"
import { ViewCustomer } from "./types"

type Props = {
  // bundle: boolean
  cashFlowInstallments: CashFlowInstallment[][]
  cashFlowOptions: CashFlowOptions[]
  discount: Discount[]
  forms: Form[]
  hasPresetBundle: boolean
  hasPresetCustomers: boolean
  tabIndex: number
  clearCashFlow: () => void
  setCashFlow: (netValue: number, selectedDefaultBilling: ViewDefaultBilling) => void
  setCashFlowOptions: React.Dispatch<React.SetStateAction<CashFlowOptions[]>>
  setDiscount: React.Dispatch<React.SetStateAction<Discount[]>>
  setForms: React.Dispatch<React.SetStateAction<Form[]>>
}

export default function CashflowForm({ cashFlowInstallments, cashFlowOptions, discount, forms, hasPresetBundle, hasPresetCustomers, tabIndex, clearCashFlow, setCashFlow, setCashFlowOptions, setDiscount, setForms }: Props) {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  // Data
  const [defaultBillings, setDefaultBillings] = useState<ViewDefaultBilling[]>([])
  const [customers, setCustomers] = useState<ViewCustomer[]>([])
  // useMemos
  const _form = useMemo(() => forms[tabIndex], [forms, tabIndex])
  // Options
  const affiliationOptions = useMemo(() => lookupTables.affiliation.map((_) => ({ id: _.id, label: `${_.tag} - ${_.option}` })), [lookupTables])
  const defaultBillingOptions = useMemo(() => lookupTables.defaultBilling.filter((_) => _.fk_id_affiliation === forms[tabIndex].affiliation?.id).map((_) => ({ id: _.id, label: _.option })), [lookupTables, forms, tabIndex])
  const beneficiaryOptions = useMemo(() => customers.filter((_) => _.fk_id_customer_type === forms[tabIndex].beneficiaryType).map((_) => ({ id: _.id, label: _.customer_name, fk_id_customer_dependence: _.fk_id_customer_dependence })), [customers, forms, tabIndex])
  const payerOptions = useMemo(() => customers.filter((_) => _.fk_id_customer_type === forms[tabIndex].payerType).map((_) => ({ id: _.id, label: _.customer_name, fk_id_customer_dependence: _.fk_id_customer_dependence })), [customers, forms, tabIndex])
  const serviceOptions = useMemo(() => lookupTables.service.map((_) => ({ id: _.id, label: _.option })), [lookupTables])
  // Errors
  const totalBillingError = useMemo(() => isNaN(forms[tabIndex]._totalBilling), [forms, tabIndex])
  const discountError = useMemo(() => discount[tabIndex].type === 'percentage' ? isNaN(discount[tabIndex]._percentage) : isNaN(discount[tabIndex]._setValue), [discount, tabIndex])
  const cashFlowError = useMemo(() => cashFlowOptions[tabIndex].mode === 'numberOfBills' ? (isNaN(cashFlowOptions[tabIndex]._numberOfBills) || cashFlowOptions[tabIndex]._numberOfBills > 48) : (isNaN(cashFlowOptions[tabIndex]._setValue) && cashFlowOptions[tabIndex].dateOfFirstCashflow !== null), [cashFlowOptions, tabIndex])
  const disableSetCashFlowButton = useMemo(() => totalBillingError || discountError || cashFlowError, [totalBillingError, discountError, cashFlowError])
  // Others
  const currencySign = useMemo(() => lookupTables.currency.find((_) => _.id === forms[tabIndex].currency)?.sign || 'R$', [lookupTables, forms, tabIndex])
  const selectedDefaultBilling = useMemo(() => defaultBillings.find((_) => _.id === forms[tabIndex].defaultBilling?.id) || null, [defaultBillings, forms, tabIndex])
  const netValue = useMemo(() => {
    // If discount is set to percentage, and there's an input for percentage
    if (discount[tabIndex].type === 'percentage' && !isNaN(discount[tabIndex]._percentage) && forms[tabIndex]._totalBilling && discount[tabIndex]._percentage >= 0 && discount[tabIndex]._percentage <= 100) {
      const _newTotalBilling = forms[tabIndex]._totalBilling * (1 - discount[tabIndex]._percentage / 100)

      return roundTwoDecimals(_newTotalBilling) // Truncating to two decimal places
    }
    // If discount is set to setValue, and there's a set value
    if (discount[tabIndex].type === 'setValue' && !isNaN(discount[tabIndex]._setValue) && forms[tabIndex]._totalBilling && discount[tabIndex]._setValue > 0 && discount[tabIndex]._setValue <= forms[tabIndex]._totalBilling) {
      const _newTotalBilling = forms[tabIndex]._totalBilling - discount[tabIndex]._setValue

      return roundTwoDecimals(_newTotalBilling) // Truncating to two decimal places
    }

    return NaN
  }, [forms, tabIndex, discount])

  const getCustomers = useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/customers?fk_id_customer_type[]=1&fk_id_customer_type[]=2`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setCustomers(data)
    } catch (err) {
      console.log(err)
      toast.error('Could not fetch customers.')
    }
  }, [])

  useEffect(() => {
    getCustomers()
  }, [getCustomers])

  const getDefaultBillings = useCallback(async (fk_id_affiliation: number | undefined) => {
    if (!fk_id_affiliation) return

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/default-billings?fk_id_affiliation=${fk_id_affiliation}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setDefaultBillings(data)
    } catch (err) {
      console.log(err)
      toast.error('Could not fetch customers.')
    }
  }, [setDefaultBillings])

  useEffect(() => {
    getDefaultBillings(forms[tabIndex].affiliation?.id)
  }, [getDefaultBillings, forms, tabIndex])

  // Default Billing
  useEffect(() => {
    if (selectedDefaultBilling) {
      setCashFlowOptions((_) => {
        const __options = { ..._ }

        __options[tabIndex].numberOfBills = selectedDefaultBilling.max_installments.toString()
        __options[tabIndex]._numberOfBills = selectedDefaultBilling.max_installments

        return __options
      })
    }
  }, [selectedDefaultBilling, setCashFlowOptions, tabIndex])

  // Bundle defaults
  useEffect(() => {
    if (!hasPresetBundle || !customers.length) return

    const _customer = customers.find((_) => _.fk_id_customer_dependence === _form.affiliation?.id && _.fk_id_customer_type === 1)

    if (!_customer) return

    if (_form.beneficiaryType === 1 && _form.beneficiary === null) {
      setForms((__form) => {
        const ___form = [...__form]

        ___form[tabIndex].beneficiary = { id: _customer.id, label: _customer.customer_name }

        return ___form
      })
    }

    if (_form.payerType === 1 && _form.beneficiary === null) {
      setForms((__form) => {
        const ___form = [...__form]

        ___form[tabIndex].payer = { id: _customer.id, label: _customer.customer_name }

        return ___form
      })
    }
  }, [customers, tabIndex])

  return <Paper sx={{ backgroundColor: '#0006' }}>
    <Grid container spacing={.75}>
      <Grid item xs={12}>
        <Typography>
          Billing
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          id='form-field-affiliation'
          options={affiliationOptions}
          value={_form.affiliation}
          onChange={(e, newValue) => {
            setForms((_) => {
              const __form = [..._]

              __form[tabIndex].affiliation = newValue

              if (!newValue && !Boolean(_form.id)) __form[tabIndex].beneficiary = null

              return __form
            })

            getDefaultBillings(newValue?.id)
          }}
          renderInput={params => <TextField {...params} label='Affiliation' />}
          disabled={Boolean(_form.id)}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          id='form-field-default-billing'
          options={defaultBillingOptions}
          value={_form.defaultBilling}
          onChange={(e, newValue) => {
            setForms((_) => {
              const __form = [..._]

              __form[tabIndex].defaultBilling = newValue

              // Filling other information of this billing
              const _defaultBilling = defaultBillings.find((_) => _.id === newValue?.id)
              const _payer = _defaultBilling?.fk_id_customer_type === 1 ? customers.find((_) => _.fk_id_customer_dependence === _defaultBilling?.fk_id_affiliation && _.fk_id_customer_type === 1) : null
              const _service = _defaultBilling ? serviceOptions.find((_) => _.id === _defaultBilling?.fk_id_service) : null

              if (_defaultBilling) {
                __form[tabIndex].totalBilling = _defaultBilling.default_value.toFixed(2)
                __form[tabIndex]._totalBilling = _defaultBilling.default_value
                __form[tabIndex].service = _service || null
                __form[tabIndex].currency = _defaultBilling.fk_id_currency
                __form[tabIndex].payerType = _defaultBilling.fk_id_customer_type
                __form[tabIndex].payer = _payer ? { id: _payer.id, label: _payer.customer_name } : null
                __form[tabIndex].payee = _defaultBilling.fk_id_payee
              }

              return __form
            })
          }}
          renderInput={params => <TextField {...params} label='Default Billing' />}
          getOptionKey={(option) => option.id}
          disabled={Boolean(_form.id)}
        />
      </Grid>
      <Grid item xs={5}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            format="dd-MMM-yy"
            label='Issue Date'
            value={_form.issueDate}
            onChange={(newValue) => setForms((_) => {
              const __form = [..._]

              __form[tabIndex].issueDate = newValue

              return __form
            })}
            disabled={Boolean(_form.id)}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={7}>
        <Autocomplete
          id='form-field-service'
          options={serviceOptions}
          value={_form.service}
          onChange={(e, newValue) => setForms((_) => {
            const __form = [..._]

            __form[tabIndex].service = newValue

            return __form
          })}
          renderInput={params => <TextField {...params} label='Service' />}
          disabled={Boolean(_form.id)}
        />
      </Grid>
      {/* Beneficiary */}
      <Grid item xs={5}>
        <TextField
          id='form-field-beneficiary-type'
          label='Beneficiary type'
          value={_form.beneficiaryType || ''}
          onChange={(e) => setForms((_) => {
            const __form = [..._]

            __form[tabIndex].beneficiaryType = Number(e.target.value)

            if (__form[tabIndex].beneficiaryType !== 1 && !Boolean(_form.id)) __form[tabIndex].beneficiary = null
            if (__form[tabIndex].beneficiaryType === 1 && !Boolean(_form.id)) {
              const _customer = customers.find((_) => _.fk_id_customer_dependence === _form.affiliation!.id && _.fk_id_customer_type === 1)!

              __form[tabIndex].beneficiary = { id: _customer.id, label: _customer.customer_name }
            }

            return __form
          })}
          disabled={Boolean(_form.id)}
          select
        >
          {
            lookupTables.customerType
              .filter((type) => [1, 2].includes(type.id)) // Affiliation and Student
              .map((type, index) => <MenuItem key={index} id={`beneficiary-type-option-${type.id}`} value={type.id}>{type.option}</MenuItem>)
          }
        </TextField>
      </Grid>
      <Grid item xs={7}>
        <Autocomplete
          id='form-field-beneficiary'
          options={beneficiaryOptions}
          value={hasPresetCustomers && _form.beneficiaryType === 2 ? { id: 0, label: 'From selected list.' } : _form.beneficiary}
          onChange={(e, newValue) => setForms((_) => {
            const __form = [..._]

            __form[tabIndex].beneficiary = newValue

            return __form
          })}
          renderInput={params => <TextField {...params} label='Beneficiary' />}
          disabled={!_form.affiliation || Boolean(_form.id) || (hasPresetCustomers && _form.beneficiaryType === 2)}
        />
      </Grid>
      {/* Payer */}
      <Grid item xs={5}>
        <TextField
          id='form-field-payer-type'
          label='Payer type'
          value={_form.payerType || ''}
          onChange={(e) => setForms((_) => {
            const __form = [..._]

            __form[tabIndex].payerType = Number(e.target.value)

            // Selecting the payer in case it's the affiliation
            const _payer = Number(e.target.value) === 1 ? customers.find((_) => _.fk_id_customer_dependence === __form[tabIndex].affiliation?.id && _.fk_id_customer_type === 1) : null

            __form[tabIndex].payer = _payer ? { id: _payer.id, label: _payer.customer_affiliation_name } : null

            return __form
          })}
          disabled={Boolean(_form.id)}
          select
        >
          {
            lookupTables.customerType
              .filter((type) => [1, 2].includes(type.id)) // Affiliation and Student
              .map((type, index) => <MenuItem key={index} id={`payer-type-option-${type.id}`} value={type.id}>{type.option}</MenuItem>)
          }
        </TextField>
      </Grid>
      <Grid item xs={7}>
        <Autocomplete
          id='form-field-payer'
          options={payerOptions}
          value={_form.payer}
          onChange={(e, newValue) => setForms((_) => {
            const __form = [..._]

            __form[tabIndex].payer = newValue

            return __form
          })}
          renderInput={params => <TextField {...params} label='Payer' />}
          disabled={!_form.affiliation || Boolean(_form.id)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id='form-field-payee'
          label='Payee'
          value={_form.payee || ''}
          onChange={(e) => setForms((_) => {
            const __form = [..._]

            __form[tabIndex].payee = Number(e.target.value)

            return __form
          })}
          disabled={Boolean(_form.id)}
          select
        >
          {
            lookupTables.company.map((_, index) => <MenuItem key={index} id={`payee-option-${_.id}`} value={_.id}>{_.option}</MenuItem>)
          }
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id='form-field-reference-year'
          label='Reference year'
          value={_form.referenceYear || ''}
          onChange={(e) => setForms((_) => {
            const __form = [..._]

            __form[tabIndex].referenceYear = Number(e.target.value)

            return __form
          })}
          disabled={Boolean(_form.id)}
          select
        >
          {
            [-1, 0, 1].map((_, index) => <MenuItem key={index} value={DateTime.utc().get('year') + _}>{DateTime.utc().get('year') + _}</MenuItem>)
          }
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <TextField
          id='form-field-currency'
          label='Currency'
          value={_form.currency || ''}
          onChange={(e) => setForms((_) => {
            const __form = [..._]

            __form[tabIndex].currency = Number(e.target.value)

            return __form
          })}
          disabled={Boolean(_form.id)}
          select
        >
          {
            lookupTables.currency.map((_, index) => <MenuItem key={index} id={`currency-option-${_.id}`} value={_.id}>{_.option}</MenuItem>)
          }
        </TextField>
      </Grid>
      <Grid item xs={9}>
        <TextField
          id='form-field-total-billing'
          label='Total Billing'
          value={_form.totalBilling}
          onChange={(e) => setForms((_) => {
            const __form = [..._]

            __form[tabIndex].totalBilling = e.target.value.trim()
            __form[tabIndex]._totalBilling = allowMoneyFormat(e.target.value.trim())

            return __form
          })}
          error={totalBillingError}
          onBlur={() => {
            setForms((_) => {
              const __form = [..._]

              __form[tabIndex].totalBilling = formatNumberToMoney(__form[tabIndex]._totalBilling)

              return __form
            })
          }}
          onFocus={() => {
            setForms((_) => {
              const __form = [..._]

              __form[tabIndex].totalBilling = __form[tabIndex]._totalBilling.toFixed(2)

              return __form
            })
          }}
          InputProps={{
            startAdornment: <InputAdornment position='start'>{currencySign}</InputAdornment>,
            sx: netValue !== _form._totalBilling && !isNaN(netValue) ? { textDecoration: 'line-through', color: '#888' } : {}
          }}
          disabled={Boolean(_form.defaultBilling) || Boolean(_form.id)}
        />
      </Grid>
      {/* Discount */}
      <Grid item xs={12}>
        <Typography>
          Discount
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id='form-field-discount-type'
          label='Discount type'
          value={discount[tabIndex].type}
          onChange={(e) => setDiscount((_) => {
            const __discount = { ..._ }

            __discount[tabIndex].type = e.target.value as 'percentage' | 'setValue'

            return __discount
          })}
          select
        >
          <MenuItem id={'discount-type-percentage'} value='percentage'>% (Percentage)</MenuItem>
          <MenuItem id={'discount-type-setValue'} value='setValue'>{currencySign} (Set value)</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id='form-field-discount'
          label='Discount'
          value={discount[tabIndex][discount[tabIndex].type]}
          onChange={(e) => setDiscount((_) => {
            const __discount = { ..._ }

            if (discount[tabIndex].type === 'percentage') {
              __discount[tabIndex].percentage = e.target.value.trim()
              __discount[tabIndex]._percentage = allowFloat(e.target.value.trim())
            }
            else {
              __discount[tabIndex].setValue = e.target.value.trim()
              __discount[tabIndex]._setValue = allowMoneyFormat(e.target.value.trim())
            }

            return __discount
          })}
          error={discountError}
          onBlur={() => {
            if (discount[tabIndex].type === 'setValue' && !isNaN(discount[tabIndex]._setValue)) {
              setDiscount((_) => ({ ..._, setValue: formatNumberToMoney(discount[tabIndex]._setValue) }))
            }
          }}
          onFocus={() => {
            if (discount[tabIndex].type === 'setValue' && discount[tabIndex]._setValue) {
              setDiscount((_) => ({ ..._, setValue: discount[tabIndex]._setValue.toFixed(2) }))
            }
          }}
          InputProps={
            discount[tabIndex].type === 'percentage' ?
              { endAdornment: <InputAdornment position='start'>%</InputAdornment> } // position="start" looks better
              :
              { startAdornment: <InputAdornment position='start'>{currencySign}</InputAdornment> }
          }
          disabled={Boolean(_form.id)}
        />
      </Grid>
      <Grid item xs={12}>
        {
          netValue !== _form._totalBilling && !isNaN(netValue) ?
            <Typography textAlign={'right'}>
              Net value: <b>{currencySign} {netValue}</b> (w/ discount)
            </Typography>
            :
            <></>
        }
      </Grid>
      {/* Cashflow */}
      {
        Boolean(_form.id) ?
          null
          :
          <>
            <Grid item xs={12}>
              <Typography>
                Cash Flow
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='form-field-cash-flow-mode'

                label='Mode'
                value={cashFlowOptions[tabIndex].mode}
                onChange={(e) => setCashFlowOptions((_) => {
                  const __options = { ..._ }

                  __options[tabIndex].mode = e.target.value as 'numberOfBills' | 'setValue'

                  return __options
                })}
                select
              >
                <MenuItem id='cashflow-mode-number-of-bills' value='numberOfBills'>Number of bills</MenuItem>
                <MenuItem id='cashflow-mode-set-value' value='setValue'>Set value per bill</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='form-field-cash-flow-nob-value'

                label={cashFlowOptions[tabIndex].mode === 'numberOfBills' ? 'Number of Bills' : 'Value'}
                value={cashFlowOptions[tabIndex][cashFlowOptions[tabIndex].mode]}
                onChange={(e) => setCashFlowOptions((_) => {
                  const __options = { ..._ }

                  if (cashFlowOptions[tabIndex].mode === 'numberOfBills') {
                    __options[tabIndex].numberOfBills = e.target.value.trim()
                    __options[tabIndex]._numberOfBills = allowNumbers(e.target.value.trim())
                  }
                  else {
                    __options[tabIndex].setValue = e.target.value.trim()
                    __options[tabIndex]._setValue = allowMoneyFormat(e.target.value.trim())
                  }

                  return __options
                })}
                error={cashFlowError}
                onBlur={() => {
                  if (cashFlowOptions[tabIndex].mode === 'setValue' && !isNaN(cashFlowOptions[tabIndex]._setValue)) {
                    setCashFlowOptions((_) => ({ ..._, setValue: formatNumberToMoney(cashFlowOptions[tabIndex]._setValue) }))
                  }
                }}
                onFocus={() => {
                  if (cashFlowOptions[tabIndex].mode === 'setValue' && cashFlowOptions[tabIndex]._setValue) {
                    setCashFlowOptions((_) => ({ ..._, setValue: cashFlowOptions[tabIndex]._setValue.toFixed(2) }))
                  }
                }}
                InputProps={
                  cashFlowOptions[tabIndex].mode === 'numberOfBills' ? {} : { startAdornment: <InputAdornment position='start'>{currencySign}</InputAdornment> }
                }
              />
            </Grid>
            {
              selectedDefaultBilling ?
                <Grid item xs={12}>
                  <Typography textAlign={'right'}>
                    {
                      cashFlowOptions[tabIndex].mode === 'numberOfBills' ?
                        <>
                          <b>Per bill:</b> {currencySign} {roundTwoDecimals(netValue / cashFlowOptions[tabIndex]._numberOfBills)}
                        </>
                        :
                        <>
                          <b>Total:</b> {currencySign} {cashFlowOptions[tabIndex]._setValue * selectedDefaultBilling?.max_installments}, <b>Installments:</b> {selectedDefaultBilling?.max_installments} (default)
                        </>
                    }
                  </Typography>
                </Grid>
                :
                <></>
            }
            <Grid item xs={5}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  format="dd-MMM-yy"
                  label="First bill's due date"
                  value={_form.issueDate}
                  onChange={(newValue) => setCashFlowOptions((_) => {
                    const __options = { ..._ }

                    __options[tabIndex].dateOfFirstCashflow = newValue

                    return __options
                  })}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={7}>
              <TextField
                id='form-field-cash-flow-remainder-options'

                label='Remainder options'
                value={cashFlowOptions[tabIndex].remainder}
                onChange={(e) => setCashFlowOptions((_) => {
                  const __options = { ..._ }

                  __options[tabIndex].remainder = e.target.value as 'first-bill' | 'last-bill' | 'equal'

                  return __options
                })}
                select
              >
                <MenuItem id='remainder-option-first-bill' value={'first-bill'}>Add to first bill</MenuItem>
                <MenuItem id='remainder-option-last-bill' value={'last-bill'}>Add to last bill</MenuItem>
                <MenuItem id='remainder-option-equal' value={'equal'}>Distribute equally</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                id='button-set-cash-flow'
                color={cashFlowInstallments[tabIndex].length ? 'error' : 'primary'}
                onClick={cashFlowInstallments[tabIndex].length ? clearCashFlow : () => setCashFlow(netValue, selectedDefaultBilling!)}
                startIcon={cashFlowInstallments[tabIndex].length ? <DeleteForever /> : <NoteAdd />}
                disabled={disableSetCashFlowButton}
              >
                {cashFlowInstallments[tabIndex].length ? 'Clear Cash Flow' : 'Set Cash Flow'}
              </Button>
            </Grid>
          </>
      }
    </Grid>
  </Paper >
}
